const handleEmailSubscriptionSignUp = (submitEvent) => {
    submitEvent.preventDefault();

    let modal = new bootstrap.Modal('#jsUpsaleModal');
    let emailRegistrationForm = document.getElementById('emailSubscriptionRegistration');
    let emailInput = document.getElementById('email_subscription_sign_up_email');
    let feedbackArea = document.getElementById('email-signup-feedback-area');
    let btn = document.getElementById('newsletter-sign-up-btn');

    btn.insertAdjacentHTML('beforeend', '<span class="ms-_5 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');
    btn.disabled = true;

    let formData = new FormData(emailRegistrationForm);
    let options = {
        method: 'POST',
        body: formData
    };

    let createFeedbackDiv = document.createElement("div");

    fetch('/p/express-user/ajax', options)
        .then(response => response.json())
        .then(body => {
            if(body.type == "redirect")
            {
                window.location.href = body.url;
                return;
            }
            else if(body.type == "error")
            {
                btn.querySelector('.spinner-border').remove();
                btn.disabled =  false;
                createFeedbackDiv.textContent = body.msg;
                createFeedbackDiv.setAttribute('id', 'email_subscription_sign_up_feedback');
                createFeedbackDiv.classList.remove("valid-feedback");
                createFeedbackDiv.classList.add("invalid-feedback", "d-block");
                emailInput.classList.remove("is-valid");
                emailInput.classList.add("is-invalid");
                if(!document.body.contains(document.getElementById('email_subscription_sign_up_feedback')))
                {
                    feedbackArea.append(createFeedbackDiv);
                }
                emailInput.setAttribute('aria-describedby', 'email_subscription_sign_up_feedback');
                emailInput.focus();
                return;
            }
            else if(body.type == "success")
            {
                btn.querySelector('.spinner-border').remove();
                btn.disabled =  false;
                createFeedbackDiv.textContent = body.msg;
                createFeedbackDiv.setAttribute('id', 'email_subscription_sign_up_feedback');
                createFeedbackDiv.classList.remove("invalid-feedback");
                createFeedbackDiv.classList.add("valid-feedback", "d-block");
                emailInput.classList.remove("is-invalid");
                emailInput.classList.add("is-valid");
                if(!document.body.contains(document.getElementById('email_subscription_sign_up_feedback')))
                {
                    feedbackArea.append(createFeedbackDiv);
                }
                emailInput.setAttribute('aria-describedby', 'email_subscription_sign_up_feedback');
            }
            modal.show();
            const upSaleModal = document.getElementById('jsUpsaleModal');
            upSaleModal.addEventListener('hidden.bs.modal', (event) =>{
                // window.location.reload();
            });
        });
}

document.addEventListener('DOMContentLoaded', function() {
    const emailRegistrationForm = document.getElementById('emailSubscriptionRegistration');
    const upSaleModal = document.getElementById('jsUpsaleModal');

    if (emailRegistrationForm && upSaleModal) {
        emailRegistrationForm.addEventListener('submit', (event) => handleEmailSubscriptionSignUp(event));
    }

})
